import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Browser } from '@capacitor/browser';
import { environment } from 'src/environments/environment';
import { Capacitor } from '@capacitor/core';

@Component({
  selector: 'app-about',
  templateUrl: './about.page.html',
  styleUrls: ['./about.page.scss'],
})
export class AboutPage implements OnInit {

  public version: string = '';

  constructor(
    public modalController: ModalController,
  ) { }

  ngOnInit() {
    this.version = 'Version: ' + environment.version + ' ' + environment.release;
  }

  close() {
    this.modalController.dismiss({})
  }

  async faq() {
    if (Capacitor.getPlatform() === 'android') {
      window.location.href = 'https://www.notion.so/Western-States-Race-Coverage-a56dcf9feefb406d88f2d8a5ab5e7401';
    } else {
      await Browser.open({ url: 'https://www.notion.so/Western-States-Race-Coverage-a56dcf9feefb406d88f2d8a5ab5e7401' });
    }
  }


}
